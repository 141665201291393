import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import history from './config/history';
import { store, saveState } from './config/store';
import App from './app/index';
import * as serviceWorker from './serviceWorker';

store.subscribe(() => {
    saveState(store.getState());
});

const app = (
        <Provider store={store}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
