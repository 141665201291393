import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import rootReducer from '../shared/reducers';
import errorMiddleware from './error-middleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if(serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (e) {
        // Ignore write errors;
    }
};

const peristedState = loadState();

const enhancer = composeEnhancers(
  applyMiddleware(
    thunkMiddleware,
    promiseMiddleware,
    loadingBarMiddleware(),
    errorMiddleware,
  )
  // other store enhancers if any
);

export const store = createStore(
    rootReducer,
    peristedState,
    enhancer
);
