import React, {Suspense} from 'react';
import {Switch, Route} from 'react-router-dom';
import Loadable from 'react-loadable';
import Loader from './layout/Loader'
import Layout from "./layout/basic/index";
import ScrollToTop from './layout/ScrollToTop';
import {checkAuth} from "../config/authProvider";
import LoginPage from './authentication/login';
import '../../node_modules/font-awesome/scss/font-awesome.scss';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

const App = () => {

    return (
            <>
                {
                    checkAuth()
                        ?
                        <Layout>
                            <ScrollToTop>
                                <Suspense fallback={<Loader/>}>
                                    <Switch>
                                        <Route path="/" component={AdminLayout}/>
                                    </Switch>
                                </Suspense>
                            </ScrollToTop>
                        </Layout>
                        :
                        <LoginPage/>

                }
            </>
    );
}
export default App
