import {loadState} from "./store";

export const checkAuth = () => {
    const state = loadState();
    if (state) {
        const {authentication} = state;
        return authentication && authentication.isAuthenticated;
    }
}

export const fullNameLoged = () => {
    const state = loadState();
    if (state) {
        const {authentication} = state;
        if (authentication && authentication.account &&
            authentication.account.personalData && authentication.account.personalData.fullName) {
            const fullName = authentication.account.personalData.fullName;
            return fullName;
        }
    }
    return "";
}


