import history from "../../config/history";

/**
 * Funcion para cambiar de ruta.
 * @param {String} path Ruta
 */
export const handleLink = path => {
	history.push(path);
}

export const goBack = () => {
	history.goBack();
}