import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import {CONSTANTS} from "../../../config/global-constants";
import {SYSTEM_LOGIN_QUERY} from "../queries";

/*console.log("TESTING LOAD ENV VAR...")
console.log("REMOTE URL-> ",process.env.REACT_APP_GRAPHQL_URL);*/

const httpLink = createHttpLink({
        uri: CONSTANTS.FM_SERVER,
});

const authUserLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(CONSTANTS.FM_TOKEN_KEY);
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const authSystemLink = setContext((_, { headers }) => {
    //const token = localStorage.getItem('token');
    return {
        headers: {
            ...headers,
            authorization: `Basic ${CONSTANTS.API_KEY}`,
        }
    }
});

export const systemClient = new ApolloClient({
    link: authSystemLink.concat(httpLink),
    cache: new InMemoryCache()
});

export const userClient = new ApolloClient({
    link: authUserLink.concat(httpLink),
    cache: new InMemoryCache()
});



export const authSystem = async () =>{
    const { data } = await systemClient.query({
        query: SYSTEM_LOGIN_QUERY,
        fetchPolicy: 'network-only'
    });
    const {systemLogin} = data;
    localStorage.setItem(CONSTANTS.FM_TOKEN_KEY, systemLogin.token);
    return systemLogin.message === "OK" ? true : false;
}
