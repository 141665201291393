import gql from "graphql-tag";

export const SYSTEM_LOGIN_QUERY = gql`
  query logIn {
    systemLogin {
      token
      code
      message
    }
  }
`;

export const USER_LOGIN_AND_DATA_QUERY = gql`
  query userLogin($userInput: String!, $keyInput: String!) {
    userLogin(user: $userInput, key: $keyInput) {
      personalData {
        fullName
        name
        firstName
        lastName
        dateBorn
        age
        sex
        phone
        email
      }
      contactData {
        phone
        email
        postalCode
        address
        locality
        province
        country
        father
        fatherPhone
        fatherEmail
        mother
        motherPhone
        motherEmail
        contactWith
        fathersMaritalStatus
      }
      healtData {
        alergies
        diets
        disabilities
      }
      identityData {
        dni
        country
        nationality
        passport
        emisionDate
        expireDate
      }
      accommodationData {
        coordinator
        director
        accommodationType
      }
      courseData {
        name
        options
        city
        country
        startDate
        endDate
        coordinator
      }
      trasnportData {
        arrivalTransfer
        departureTransfer
        managedFlight
        flights {
          company
          aport
          private
          flyNumber
          departureAirPort
          departureDate
          departureHour
          arrivalAirPort
          arrivalDate
          arrivalHour
        }
      }
      documents {
        name
        url
      }
    }
  }
`;
