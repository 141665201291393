import { REQUEST, SUCCESS, FAILURE } from './action-type.util';
import { CONSTANTS } from '../../config/global-constants';
import {Base64} from "js-base64";
import {userClient} from "../graphql/client";
import { USER_LOGIN_AND_DATA_QUERY } from "../graphql/queries";

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE'
};

const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  account: {},
  errorMessage: null, // Errors returned from server side
  redirectMessage: null,
  sessionHasBeenFetched: false,
  idToken: null,
  logoutUrl: "/login"
};

// Reducer

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        errorMessage: action.payload,
        loginError: true
      };
      case SUCCESS(ACTION_TYPES.LOGIN):
          localStorage.setItem('isAuth', true);
          return {
        ...state,
        loading: false,
        loginError: false,
        loginSuccess: true,
        isAuthenticated: true,
        account: action.payload
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
      };
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        redirectMessage: action.message
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        isAuthenticated: false
      };
    default:
      return state;
  }
};

export const displayAuthError = message => ({
  type: ACTION_TYPES.ERROR_MESSAGE,
  message
});

export const login = (username, password, rememberMe = false ) => async dispatch => {

    const base64 = Base64.encode(username +':'+password);
    const response = await userClient.query({
        query: USER_LOGIN_AND_DATA_QUERY,
        variables: {
            "userInput" : username, //"PAR0008630" Pandora32
            "keyInput": base64 //UEFSMDAwODYzMDpQYW5kb3JhMzI=
        },
        fetchPolicy: 'network-only'
    });

    const { data: { userLogin = null } = {} } = await response || {};
    await dispatch({
        type: userLogin ? SUCCESS(ACTION_TYPES.LOGIN) : FAILURE(ACTION_TYPES.LOGIN),
        payload: userLogin ? userLogin : "Credenciales incorrectas. Verifique su usuario y contraseña."
    });
};

export const clearAuthToken = () => {
    localStorage.removeItem('isAuth');
    localStorage.removeItem('username');
    localStorage.removeItem('state');
    localStorage.removeItem(CONSTANTS.FM_TOKEN_KEY);
    sessionStorage.removeItem(CONSTANTS.FM_TOKEN_KEY);
};

export const logout = () => dispatch => {
  clearAuthToken();
  dispatch({
    type: ACTION_TYPES.LOGOUT
  });
};

export const clearAuthentication = messageKey => dispatch => {
  clearAuthToken();
  dispatch(displayAuthError(messageKey));
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH
  });
};
