import { combineReducers } from 'redux';

import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import authentication from './authentication';
import styleReducer from './styleReducer';

const rootReducer = combineReducers({
  styleReducer,
  authentication,
  loadingBar
});

export default rootReducer;
