export const ROUTES = {
    ROOT: "/",
    HOME: "/home",
    PERSONAL_INFO: "/personal-info",
    COURSE: "/course",
    ACCOMMODATION: "/accommodation",
    TRANSPORT: "/transport",
    DOCUMENTS: "/documents",
};

