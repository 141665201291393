import React from 'react';
import { connect } from 'react-redux';
import Layout from "../../layout/basic/index";
import Loader from '../../layout/Loader'
import logo from '../../../assets/images/logo.svg';
import { login } from '../../../shared/reducers/authentication';
import {handleLink} from "../../../shared/util/link-utils";
import {authSystem} from "../../../shared/graphql/client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../../../assets/scss/style.scss';
import {ROUTES} from "../../../config/routes-constants";

class LoginPage extends React.Component {

    state = {
        username: "",
        password: "",
        rememberMe: false
    }


    handleLogin = async () => {

        const { username, password, rememberMe } = this.state;
        const auth = await authSystem();

        if(auth) {
            await this.props.login(username, password, rememberMe);
            handleLink(ROUTES.HOME);
        }

        const {loginError, errorMessage} = this.props;
        if(loginError){
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };


    changeHandler = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render () {
        const {loading} = this.props;
        return(
            <Layout>
                    {loading && <Loader/>}
                    <div className="auth-wrapper">
                        <div className="auth-content">
                            <div className="auth-bg">
                                <span className="r"/>
                                <span className="r s"/>
                                <span className="r s"/>
                                <span className="r"/>
                            </div>
                            <div className="card">
                                <div className="card-body text-center">
                                    <div className="mb-4">
                                        <img src={logo} alt="login-user"/>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="text" name="username" onChange={this.changeHandler} className="form-control" placeholder="Nombre de usuario"/>
                                    </div>
                                    <div className="input-group mb-4">
                                        <input type="password" name="password" onChange={this.changeHandler} className="form-control" placeholder="Contraseña"/>
                                    </div>
                                    <button className="btn btn-primary shadow-2 mb-4" onClick={()=> this.handleLogin()}>Acceder</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
            </Layout>
        );
    }
}
function mapStateToProps(state) {
    const { authentication } = state;
    return {
        isAuthenticated: authentication.isAuthenticated,
        loginError: authentication.loginError,
        loading: authentication.loading,
        loginSuccess: authentication.loginSuccess,
        errorMessage: authentication.errorMessage,
    };
}
const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);